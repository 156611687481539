<template>
    <div item-layout="vertical" style="text-align: left; padding: 30px 3%">
        <div style="float: left;width: 80%;font-size: small">
            <Input search id="input" enter-button placeholder="Search Projects..." @on-search="search"/>
        </div>
        <div style="float: left;width: 100%" v-for="item in data" :key="item.title">
            <a :href="'/#/projectDetail?id='+item.id">
            <Card style="background-color: white;margin-top: 22px;padding: 20px 15px;">
                <div>
                    <h2>{{item.projectName}}</h2>
                    <p>{{item.outline}}</p>
                </div>
                <!--<div style="float: right;margin-bottom: 15px; margin-right: 4%">-->
                    <!--<Icon type="ios-star-outline"/>123-->
                <!--</div>-->
            </Card>
            </a>
        </div>

        <div class="page_button">
            <Page @on-change="loadMore" :total="maxPage" :current="currentPage" :page-size="20" prev-text="Previous" next-text="Next" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "userRepositories",
        data () {
            return {
                currentPage: 1,
                maxPage: 1,
                data: [
                ]
            }
        },
        methods:{
            loadData(){
                var Tself = this;
                var queryvo={
                    founder: this.getCookie("user_id"),
                    query:''
                }
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/project/findByQueryAndUser',
                    data: queryvo
                }).then(function (response) {
                    var data = response.data.data.items.content;
                    Tself.data = data.map(
                        function(obj){
                            return {
                                projectName: obj.name,
                                outline: obj.abstractInfo,
                                projectType: 'founder',
                                id: obj.id
                            }
                        }
                    )

                }).catch(function (error) {
                    console.log(error);
                });

            },
            getCookie(cname){
                let name = cname + "=";
                let ca = document.cookie.split(';');
                for(let i=0; i<ca.length; i++) {
                    let c = ca[i].trim();
                    if (c.indexOf(name) !== -1) { return c.substring(name.length,c.length); }
                }
                return "";
            },
            search(val){
                var Tself = this;
                var queryvo={
                    founder: this.getCookie("user_id"),
                    query:val
                }
                this.$axios({
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    method: 'post',
                    url: '/project/findByQueryAndUser',
                    data: queryvo
                }).then(function (response) {
                    var data = response.data.data.items.content;
                    Tself.data = data.map(
                        function(obj){
                            return {
                                projectName: obj.name,
                                outline: obj.abstractInfo,
                                projectType: 'founder',
                                id: obj.id
                            }
                        }
                    )

                }).catch(function (error) {
                    console.log(error);
                });

            },
            loadMore(){
                //todo
                //获取当前页码下的项目
               /* this.$axios.post(
                    "/user/findById",
                    {
                        amountVO:{
                            id: this.userName,
                        }
                    }
                ).then((response) => {
                    this.amount = response.data.items.content.balance;
                    // this.$Message.success('OK!');
                }).catch((error) => {
                    this.$Message.error(error.message);
                });

                */
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>

<style scoped>
    .page_button{
        float:left;
        width: 100%;
        padding-top: 20px;
        text-align: center;
    }
</style>
